import React, { forwardRef } from "react";
import styled from "styled-components";
import { colors, fontSize, mainColors } from "../../constants";
import CommonTooltip from "../common/CommonTooltip";

export const Metadata = forwardRef((props, ref) => {
  const { projectName, metadata } = props;

  return (
    <MetadataContainer id="sheet-metadata-container" ref={ref}>
      <MetadataHeaderContainer>
        <HeaderLabel />
        <MetadataHeader>메타 데이터</MetadataHeader>
      </MetadataHeaderContainer>
      <MetadataInfoContainer>
        <MetadataItem>
          <MetadataRow>
            <MetadataTitle>프로젝트 정보</MetadataTitle>
            <CommonTooltip title={projectName}>{projectName}</CommonTooltip>
          </MetadataRow>
          <MetadataRow>
            <MetadataTitle>프로젝트 설명</MetadataTitle>
            <MetadataText>{metadata.description}</MetadataText>
          </MetadataRow>
        </MetadataItem>
        <MetadataItem>
          <MetadataRow>
            <MetadataWriteTitle>문서 이름</MetadataWriteTitle>
            <CommonTooltip title="data_sheet.json">
              data_sheet.json
            </CommonTooltip>
          </MetadataRow>
          <MetadataRow>
            <MetadataWriteTitle>작성자</MetadataWriteTitle>
            <MetadataText>홍길동</MetadataText>
          </MetadataRow>
        </MetadataItem>
        <MetadataItem>
          <MetadataRow>
            <MetadataWriteTitle>작성일</MetadataWriteTitle>
            <MetadataText>{metadata.created_date}</MetadataText>
          </MetadataRow>
          <MetadataRow>
            <MetadataWriteTitle>수정일</MetadataWriteTitle>
            <MetadataText>{metadata.modified_date}</MetadataText>
          </MetadataRow>
        </MetadataItem>
      </MetadataInfoContainer>
    </MetadataContainer>
  );
});

const MetadataContainer = styled.div`
  margin: 20px;
  padding: 10px 15px 0px;
  background-color: ${colors.WHITE};
  border: 2px solid ${mainColors.POINT_BLUE};
  border-radius: 15px;
  overflow: hidden;
`;

const MetadataHeaderContainer = styled.div`
  margin: 10px;
  gap: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderLabel = styled.div`
  background-color: ${mainColors.POINT_BLUE};
  height: ${parseFloat(fontSize.LARGE) + 7 + "px"};
  width: 8px;
  transform: translateY(1px);
`;

const MetadataHeader = styled.label`
  font-size: ${fontSize.LARGE};
  font-weight: bold;
`;

const MetadataInfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  gap: 10px;
`;

const MetadataItem = styled.div`
  flex: 1 1 auto;
  min-width: 300px;
  padding: 16px;
  border-radius: 8px;
  overflow: hidden;
`;

const MetadataRow = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
  overflow: hidden;
`;

const MetadataTitle = styled.p`
  font-weight: bold;
  color: ${colors.BLACK};
  margin: 0;
  flex-basis: 110px;
  flex-shrink: 0;
`;

const MetadataWriteTitle = styled.p`
  font-weight: bold;
  color: ${colors.BLACK};
  margin: 0;
  flex-basis: 76px;
  flex-shrink: 0;
`;

const MetadataText = styled.p`
  color: ${colors.GRAY_700};
  margin: 0;
  flex-grow: 1;
  text-align: left;
  min-width: 0;
  overflow: hidden;
`;
